.logo {
  position: relative;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
}

.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
  background-color: #6868b0;
}

.ant-menu.ant-menu-sub.ant-menu-inline[data-menu-list] {
  background: #595996;
}

div.ant-layout-sider-trigger {
  background: #595996;
}

.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-dark.ant-menu-inline-collapsed {
  background-color: #6868b0;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #5c5c9e !important;
}
.site-layout-background.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  gap: 1rem;
  background-color: #6868b0;
  background: linear-gradient(
    45deg,
    rgba(104, 104, 176, 1) 30%,
    rgba(0, 188, 242, 0.75) 100%
  );
}
.site-layout-background.header svg {
  width: 40px;
  height: 40px;
  padding: 10px;
  border: 1px solid #fafafa;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
  color: #fafafa;
  transition: all 0.3s;
}
